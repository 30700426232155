import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";
import MediaCarousel from "../components/organisms/media-carousel";

function DesignCollection({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Collection" />
      <h2>Collection</h2>
      <div className="design__stories">
        <DesignStory
          title="Collection"
          component={MediaCarousel}
          subComponent={MediaBlock}
          type="carousel"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignCollection.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignCollection.defaultProps = {
  props: {},
};

export default DesignCollection;
